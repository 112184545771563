import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['toast'];

    connect() {
        this.token = document.querySelector('meta[name="csrf-token"]').content
    }

    get() {
        fetch(window.location.href + '/access_flow',
            {
                method: 'GET',
                headers: {
                    'X-CSRF-Token': this.token
                }
            }).then(value => {
                value = value.json();
                value.then((data) => {
                    const clipboard = navigator.clipboard;
                    if (clipboard === undefined) {
                        console.log('clipboard is undefined - dev environment?');
                        console.log(data.url)
                    } else {
                        clipboard.writeText(data.url);
                    }
                    (new bootstrap.Toast(this.toastTarget)).show()
                })
        });
    }
}